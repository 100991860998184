import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

export default function Booking({ store }) {
  const [users, setUsers] = useState([]);
  const toast = useToast();
  const token = Cookies.get("notatmrp_seller");

  useEffect(() => {
    if (!store || !store._id) return;

    const fetchBookedUsers = async () => {
      try {
        const response = await axios.get(
          `https://api.notatmrp.com/tickets/all/store/${store._id}`,
          {
            headers: {
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const allUsers = response.data.flatMap((ticket) => ticket.bookedUsers);
        setUsers(allUsers);
      } catch (error) {
        toast({
          title: "Error.",
          description: "There was an error fetching the booked users.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchBookedUsers();
  }, [store, token, toast]);

  const tableWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "60%" });

  return (
    <Box bg="purple.50" p={6} minH="100vh">
      <Box
        bg="white"
        p={6}
        rounded="md"
        w={tableWidth}
        mx="auto"
        overflowX="auto"
      >
        <Heading mb={6} color="purple.800">
          Booked Users
        </Heading>
        <Table variant="simple" size="sm" colorScheme="purple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th isNumeric>Quantity</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user._id.$oid}>
                <Td>{user.fullname || "N/A"}</Td>
                <Td>{user.phone || "N/A"}</Td>
                <Td>{user.email || "N/A"}</Td>
                <Td isNumeric>{user.quantity}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
