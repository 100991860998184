import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

function OtpModal({ isOpen, onClose, phoneNumber, orderId }) {
  const toast = useToast();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [resendTimer]);

  const verifyOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.notatmrp.com/auth/retailer/verify-otp`,
        {
          phoneNumber: `+91${phoneNumber}`,
          orderId,
          otp,
        },
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "OTP Verified",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      console.log(response.data);
      if (response.data.token) {
        Cookies.set("notatmrp_seller", response.data.token, { expires: 30 });
        setIsVerified(true);

        if (response.data.isNewUser) {
          window.location.href = "/completeprofile";
        } else {
          window.location.href = "/user";
        }
      }
    } catch (error) {
      setError(
        "Failed to verify OTP: " +
          (error.response?.data?.message || error.message)
      );
      toast({
        title: "Error",
        description: "Failed to verify OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    await verifyOtp();
  };

  const resendOtp = async () => {
    try {
      setLoading(true);
      await axios.post(
        `https://api.notatmrp.com/auth/retailer/resend-otp`,
        { orderId },
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "OTP Resent",
        description: "OTP has been resent to your mobile number.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setResendTimer(60);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to resend OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (isVerified) {
    return <Navigate to="/user" />;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter OTP</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p className="text-center text-[15px] font-serif my-2">
            Please enter the OTP sent to {phoneNumber}
          </p>
          <form onSubmit={handleOtpSubmit}>
            <div className="mb-6">
              <input
                type="text"
                className="w-full p-3 rounded-md border text-gray-800 focus:ring-2 focus:ring-purple-700"
                value={otp}
                required
                placeholder="Please enter the OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <Button
              type="submit"
              bg="purple-500"
              color="white"
              className="w-full p-3 rounded-[20px] text-white bg-purple-600 hover:bg-purple-700 focus:ring-2 focus:ring-purple-500"
              isLoading={loading}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </Button>
          </form>
          {resendTimer > 0 ? (
            <p className="text-center text-[15px] text-gray-600 mt-4">
              Resend OTP in {resendTimer} seconds
            </p>
          ) : (
            <Button
              className="mt-4 w-full p-3 rounded-[20px] text-white bg-purple-600 hover:bg-purple-700 focus:ring-2 focus:ring-purple-500"
              onClick={resendOtp}
              disabled={loading}
            >
              Resend OTP
            </Button>
          )}
        </ModalBody>
        <ModalFooter>
          {error && <p className="text-center text-sm text-red-500">{error}</p>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default OtpModal;
