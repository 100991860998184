import React from "react";
import "./main-layout.scss";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import EventSidebar from "../components/sidebar/EventSidebar";

const MainLayout = ({ user, store }) => {
  if (user && user.stores && user.stores.length === 0) {
    // Navigate to home page
    window.location.href = "/completeprofile";
  }

  const hasSpecialStoreCategory =
    store && store.StoreCategory === "669a0b5b877265140df50307";

  return (
    <>
      {hasSpecialStoreCategory ? (
        <>
          <EventSidebar />
          <div className="main">
            <div className="main__content">
              <TopNav />
              <Outlet />
            </div>
          </div>
        </>
      ) : (
        <>
          <Sidebar />
          <div className="main">
            <div className="main__content">
              <TopNav />
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MainLayout;
