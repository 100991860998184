import { useState } from "react";

import { Box, Progress } from "@chakra-ui/react";
import CompleteProfile from "./CompleteProfile";
import AddStores from "./AddStores";
// import UploadDocuments from "./UploadDocuments";
import useRetialerData from "../service/useRetialerData";

export default function MultiStepForm() {
  const [step, setStep] = useState(1);
  const [profileData, setProfileData] = useState({});
  const [storeData, setStoreData] = useState({});
  const { user, error } = useRetialerData();

  const handleProfileComplete = (data) => {
    setProfileData(data);
    setStep(2);
  };

  const handleStoreComplete = (data) => {
    setStoreData(data);
    // console.log("Profile Data:", profileData);
    // console.log("Store Data:", storeData);
    alert("Profile completion process is finished!");
  };

  //   const handleDocumentsComplete = () => {
  //     console.log("Profile Data:", profileData);
  //     console.log("Store Data:", storeData);
  //     alert("Profile completion process is finished!");
  //   };

  const progressValue = (step / 2) * 100;

  if (error) {
    return (
      <p className="min-h-[100vh] flex items-center justify-center">
        Error loading user details or Check Your Internet Connection.
      </p>
    );
  }

  if (user && user.stores && user.stores.length > 0) {
    // Navigate to home page
    window.location.href = "/";
  }

  return (
    <Box>
      <Progress value={progressValue} size="xs" colorScheme="purple" mb={4} />
      {step === 1 && <CompleteProfile onComplete={handleProfileComplete} />}
      {step === 2 && <AddStores onComplete={handleStoreComplete} user={user} />}
      {/* {step === 3 && (
        <UploadDocuments
          onComplete={handleDocumentsComplete}
          id={user?.id || ""}
        />
      )} */}
    </Box>
  );
}
