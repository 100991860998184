import React from "react";
import "./user-info.scss";

const UserInfo = ({ store }) => {
  if (!store) {
    return <div className="user-info">Loading...</div>;
  }

  return (
    <div className="user-info lg:border bg-white shadow">
      {/* <div className="user-info__img">
        {store.logourl ? (
          <img src={store.logourl} alt="Store logo" className="object-contain" />
        ) : (
          <div>No logo available</div>
        )}
      </div> */}
      <div className="user-info__name">
        {store.StoreName ? (
          <span>{store.StoreName}</span>
        ) : (
          <span>No store name available</span>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
