import Navbar from "./components/Navbar";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "axios";
import { Navigate } from "react-router-dom";
import useRetialerData from "../service/useRetialerData";

export default function CompleteProfile({ onComplete }) {
  const { user, error } = useRetialerData();
  const toast = useToast();
  const token = Cookies.get("notatmrp_seller");

  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    email: "",
    gender: "",
    age: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    if (user) {
      setFormData({
        fullname: user.fullname || "",
        phone: user.phone || "",
        email: user.email || "",
        gender: user.gender || "",
        age: user.age || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" && /^\d{10}$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: "+91" + value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setApiError(null);

    try {
      const response = await axios.patch(
        `https://api.notatmrp.com/auth/retailer/editretailerdetails`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(response.data.error || "Failed to update profile.");
      }

      toast({
        title: "Profile updated.",
        description: "Your profile has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onComplete(formData);
    } catch (err) {
      setApiError(err.message);
      toast({
        title: "Error",
        description: "Email or phone is already taken",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("notatmrp_seller");
    if (!token) {
      <Navigate to="/addstores" />;
    }
  }, []);

  if (error) {
    return (
      <p className="min-h-[100vh] flex items-center justify-center">
        Error loading user details or Check Your Internet Connection.
      </p>
    );
  }

  return (
    <section>
      <Navbar user={user} />
      <div className="flex justify-center items-center shadow-lg mx-auto max-w-screen-xl m-5 ">
        <Box className="lg:w-2/5 p-5">
          <Text className="text-center font-bold text-[30px] mb-[10px]">
            Complete Your Profile
          </Text>
          <Stack spacing={4}>
            <form onSubmit={handleSubmit}>
              <FormControl id="fullname" isRequired>
                <FormLabel>Full Name</FormLabel>
                <Input
                  name="fullname"
                  value={formData.fullname}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="phone" isRequired mt={4}>
                <FormLabel>Phone Number (without +91)</FormLabel>
                <Input
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="email" isRequired mt={4}>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="gender" isRequired mt={4}>
                <FormLabel>Gender</FormLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>

              <FormControl id="age" isRequired mt={4}>
                <FormLabel>Age</FormLabel>
                <Select name="age" value={formData.age} onChange={handleChange}>
                  <option value="">Select Age Range</option>
                  <option value="under 15">Under 15</option>
                  <option value="15-18">15-18</option>
                  <option value="18-24">18-24</option>
                  <option value="24-30">24-30</option>
                  <option value="30-40">30-40</option>
                  <option value="40-50">40-50</option>
                  <option value="50+">50+</option>
                </Select>
              </FormControl>

              {apiError && (
                <Text color="red.500" mt={4}>
                  {apiError}
                </Text>
              )}

              <Button
                mt={4}
                colorScheme="purple"
                isLoading={isSubmitting}
                type="submit"
              >
                Save Changes
              </Button>
            </form>
          </Stack>
        </Box>
      </div>
    </section>
  );
}
