import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import MainLayout from "./layout/MainLayout";
import "./index.css";
import OrderList from "./pages/OrderList";
import CustomerList from "./pages/CustomerList";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import "./pages/editor.css";
import Home from "./pages/Home";
import AddStores from "./pages/AddStores";
import MultiStepForm from "./pages/MultiStepForm";
import AddCashback from "./components/cashback/AddCashback";
import useRetialerData from "./service/useRetialerData";
import useStoreData from "./service/useStoreData";
import AddOffers from "./pages/AddOffers";
import AddEvents from "./components/event/AddEvents";
import AddMenu from "./components/menu/AddMenu";
import AddNotification from "./components/notification/AddNotification";
import UpdateStore from "./pages/EditStore";
import EditProfile from "./pages/EditProfile";
import AddTicket from "./pages/AddTicket";
import Booking from "./pages/Booking";
import EventDashboard from "./pages/EventDashboard";
import EventEditStore from "./pages/EventEditStore";
import { useEffect } from "react";
export default function App() {
  const { user, error } = useRetialerData();
  const storeId = user && user.stores ? user.stores[0] : null;

  const { store } = useStoreData(storeId);

  const storeData = store ? store : null;

  const hasSpecialStoreCategory =
    store && store.StoreCategory === "669a0b5b877265140df50307";

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <MainLayout user={user} store={store} />
          </ProtectedRoutes>
        }
      >
        {hasSpecialStoreCategory ? (
          <>
            <Route
              index
              element={
                <ProtectedRoutes>
                  <EventDashboard />
                </ProtectedRoutes>
              }
            />
          </>
        ) : (
          <>
            <Route
              index
              element={
                <ProtectedRoutes>
                  <Dashboard store={store} />
                </ProtectedRoutes>
              }
            />
          </>
        )}

        {/* <Route
          path="scanner"
          element={
            <ProtectedRoutes>
              <Scanner />{" "}
            </ProtectedRoutes>
          }
        /> */}

        <Route
          path="cashback"
          element={
            <ProtectedRoutes>
              <AddCashback user={user} store={storeData} />{" "}
            </ProtectedRoutes>
          }
        />

        <Route
          path="addoffers"
          element={
            <ProtectedRoutes>
              <AddOffers store={store} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="addevents"
          element={
            <ProtectedRoutes>
              <AddEvents store={store} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="addmenu"
          element={
            <ProtectedRoutes>
              <AddMenu store={store} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="addnotification"
          element={
            <ProtectedRoutes>
              <AddNotification store={store} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="orders"
          element={
            <ProtectedRoutes>
              <OrderList store={store} />{" "}
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="products"
          element={
            <ProtectedRoutes>
              <Products />{" "}
            </ProtectedRoutes>
          }
        /> */}
        <Route
          path="customers"
          element={
            <ProtectedRoutes>
              <CustomerList />{" "}
            </ProtectedRoutes>
          }
        />

        <Route
          path="addstores"
          element={
            // <ProtectedRoutes>
            <AddStores />
            // </ProtectedRoutes>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoutes>
              <UpdateStore store={store} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="editstore"
          element={
            <ProtectedRoutes>
              <EventEditStore store={store} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="editprofile"
          element={
            <ProtectedRoutes>
              <EditProfile user={user} />{" "}
            </ProtectedRoutes>
          }
        />

        <Route
          path="addticket"
          element={
            <ProtectedRoutes>
              <AddTicket store={storeData} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="bookings"
          element={
            <ProtectedRoutes>
              <Booking store={storeData} />
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="manage"
          element={
            <ProtectedRoutes>
              <Manage />{" "}
            </ProtectedRoutes>
          }
        /> */}
      </Route>

      <Route path="completeprofile" element={<MultiStepForm user={user} />} />
      <Route path="login" element={<Home />} />
    </Routes>
  );
}
